.dashboard {
    height: inherit;
    width: 100%;
    padding: 30px;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    .container {

        @media (max-width : 1199px) {
            display: flex;
            justify-content: center;
        }


        .cardContainer {


            .card {
                height: 100%;
                padding: 17px;
                border-radius: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .contentContainer {
                    width: calc(100% - 200px);
                    color: white;



                    @media (max-width : 1399px) {
                        width: calc(100% - 70px);
                    }

                    h1.cardHeader {
                        font-size: 1.5rem;
                        font-weight: 500;
                        margin: 0;

                    }

                    p.cardDescription {
                        font-size: 14px;
                        font-weight: 300;
                        margin: 15px 0;
                    }

                    button.manage {
                        padding: 5px 20px;
                        margin-top: 15px;
                        border: none;
                        border-radius: 20px;
                        background-color: white;

                    }
                }

                .percentageContainer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 200px;

                    .percentage {
                        color: white;
                        font-size: 4rem;
                        height: 90px;
                        margin: 0;
                        margin-top: 6px;

                    }


                    .circle {
                        color: white;
                        width: 20px;
                    }

                    .progressLabel {
                        color: white;
                        font-size: 1.8rem;
                    }


                }
            }
        }

    }


}