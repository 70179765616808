.inputContainer {
    border: none !important;
    margin: 30px 0 0 0 !important;


    label {
        padding: 0 10px;
    }


}

.input,
.select {
    border-radius: 20px !important;


    span {
        font-size: 0.6rem !important;
        width: 20px !important;
        height: 20px !important;
        top: inherit !important;
        right: 10px !important;
    }


    &:focus-visible {
        outline: none !important;


    }

    fieldset {
        border-color: lightgrey !important;
        top: -8px !important;

    }

    &:hover,
    &:focus-within {
        fieldset {
            border-color: lightgrey !important;
        }
    }

}

.timePicker {
    margin: 30px 0 0 0 !important;

    label {
        padding: 0 10px;
        top: -20%;

        &.focused {
            top: 0 !important;
            background-color: white !important;
            color: #6268F3 !important;
        }


    }

    &.removeMargin {
        margin: 0 !important
    }


    .timePickerInput {
        border-radius: 20px !important;





        &:hover,
        &:focus-within {

            fieldset {
                border-color: lightgrey !important;


            }
        }

        input {
            padding: 8.5px 14px;


        }
    }
}


.colored {
    background-color: white !important;
    color: #6268F3 !important;




}



.checkboxLabel {
    margin: 8px 0 !important;

    .checkBoxError {
        color: red !important;

    }

    span {
        font-size: 0.8rem !important;
        padding: 0 5px !important;




    }

    .color {
        color: #6268F3 !important;
    }


    a span {
        margin: 0 !important;
        color: #6268F3 !important;
        font-weight: 600 !important;
        padding: 0 !important;
    }
}


.icon {
    font-size: 1.3rem;
    cursor: pointer;


    svg {
        color: rgb(165, 164, 164);

    }

}