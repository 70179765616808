@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible&display=swap');

.register {
    width: 100%;
    min-height: 100vh;
    height: 100vh;


    .title {
        min-height: 100vh;
        background-image: url("./images/background.svg");
        background-size: auto 100%;
        position: relative;
        justify-content: center;

        .content {
            width: 100%;
            height: 100vh;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 1;
            color: white;
            text-align: center;

            .titleImage {
                width: 70%;
                top: 50vh;
                transform: translateY(-10vh);
            }

            .info {
                position: absolute;
                bottom: 4rem;

                @media (max-width:1300px) {
                    h1 {
                        font-size: 1.5rem;
                    }

                    p {
                        font-size: 1rem;
                        margin: 10px;
                    }
                }

                @media (max-height:600px) {
                    bottom: 2rem;
                }
            }

        }
    }

    .form {
        padding: 2rem 10rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: auto;

        @media (max-width : 1400px) {
            padding: 3rem 6rem;

        }

        @media (max-width : 1200px) {
            padding: 3rem 3rem;

        }

        .header {
            display: flex;
            flex-direction: row;
            text-align: right;
            justify-content: right;
            width: 100%;
            margin-bottom: 25px;

            img {
                margin-right: auto;
                width: 40px;
                height: 40px;
            }

            p {
                margin: 0 10px 0 auto;
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 15px;
                font-family: 'Atkinson Hyperlegible', sans-serif;
                color: grey;
            }

            button {
                background-color: #6268F3;
                border-radius: 50px;
                padding: 8px 30px;
                font-size: 14px;
                text-align: center;
                border: none;
                margin-left: 20px;
                color: white;
                margin: 0 6px;
            }
        }

        h1 {
            width: 100%;
            margin: 10px 0;
            font-weight: 800;
            font-size: 2rem;
        }

        p.info {
            width: 100%;
            margin: 10px 0;
            color: grey;
            font-size: 15px;
            text-align: left;
        }

        hr {
            margin: 12px 0;
            border: 0.6px 0 0 0 solid grey;
            width: 100%;
        }

        .fields {
            width: 100%;
            margin: 0px auto 30px 0;

            .error {
                font-size: 1rem;
                color: red;
                margin: 5px 0 0 10px;
                font-weight: 500;
            }

            .container {
                display: flex;
                justify-content: center;
                align-items: center;
            }



            .description {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0 15px;

                .color {
                    color: #6268F3;
                }



                span {
                    font-size: 0.8rem;
                    padding: 0 5px;
                    user-select: none;

                }

                .reset {
                    margin-left: auto;
                    color: #6f81a5;
                    font-size: 0.8rem;
                    text-decoration: none;
                    font-weight: 500;
                    cursor: pointer;
                }
            }


        }

        .information {
            display: flex;
            flex-direction: column;
            margin: 40px 0;
            user-select: none;


        }

        .submit {
            width: 50%;
            background-color: #6268F3;
            color: white;
            border-radius: 50px;
            padding: 12px 0;
            font-size: 18px;
            font-weight: 700;
            border: none;
            margin: 20px 0 20px 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            &.submitted {
                background-color: #a3a5e4;
                cursor: not-allowed;
                position: relative;
            }

            span {
                color: white;
                margin: 0 20px;
                position: absolute;
                right: 27%;
            }

        }
    }



}