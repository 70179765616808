.reportManagement {
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .content {
        width: 100%;
        height: -webkit-fill-available;
        padding: 20px 40px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .title {
            width: 100%;
            padding: 20px 0;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid lightgrey;

            h1.header {
                font-size: 1.6rem;
                margin: 0;
                margin-right: auto;
            }

        }

        .select {
            min-width: 40px;
            width: fit-content;
            padding: 2px 10px;
            margin: 40px 20px 0px auto;
            border: none;
            border-radius: 30px;
            color: white;
            background-color: #6268F3;
            font-size: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;

            div {
                padding: 10px 30px 10px 15px;

                @media (max-height:800px) {
                    padding: 7px 25px 7px 12px;
                }
            }

            svg {
                color: white;
            }

        }

        .tableContainer {
            width: 100%;
            height: -webkit-fill-available;
            overflow: auto;

            .tableComponent {
                margin: 10px 0 10px;
                box-shadow: none;
                border-radius: 10px;

                .tableHeaderContainer {
                    background-color: #6268F3;

                    th {
                        color: white;
                        font-size: 17px;

                    }
                }

                .emptyDataContainer,
                .loaderContainer {
                    width: 100%;
                    text-align: center;
                    margin: 30px 0;
                    padding: 40px 0;
                    border-bottom: none;

                    .emptyData {
                        font-size: 1.6rem;
                        color: grey;
                    }
                }

                p.reason {
                    margin: 0;
                    width: fit-content;
                }
            }
        }

        .paginationContainer {
            padding: 10px 40px;

            .paginaton {
                margin-left: auto;
            }


            .selectedPage {
                background-color: #6268F3;
                color: white
            }
        }
    }
}