.ratingAndReviewManagement {
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .content {
        width: 100%;
        height: -webkit-fill-available;
        padding: 20px 40px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .title {
            width: 100%;
            padding: 20px 0;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid lightgrey;

            h1.header {
                font-size: 1.6rem;
                margin: 0;
                margin-right: auto;
            }

        }

        .subTitle {
            width: 100%;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid lightgrey;

            h3.header {
                margin: 0;
                padding: 12px 5px;
                margin-right: auto;
                color: #6268F3;
                font-size: 1.2rem;
                font-weight: 500;
                border-bottom: 1px solid #6268F3;

                @media (max-height:800px) {
                    padding: 8px 5px;
                    font-size: 1rem;
                }

                span.count {
                    margin: 0;
                    margin-left: 3px;
                }
            }

        }

        .loaderContainer {
            height: inherit;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .loader {
                font-size: 4rem;
            }
        }

        .cards {
            padding: 20px 5px;
            height: inherit;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .allRatings {
                display: flex;
                flex-direction: column;
                background-color: white;
                border-radius: 20px;

                div.ratingHeader {
                    width: 100%;
                    padding: 15px 30px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    @media (max-height:800px) {
                        padding: 12px 30px;
                    }

                    h4.header {
                        margin: 0;
                        font-size: 1.5rem;
                        font-weight: 800;

                        @media (max-height:800px) {
                            font-size: 1.2rem;
                        }
                    }

                    div.ratingContainer {
                        margin: 0;
                        margin-right: auto;
                        margin-left: 20px;
                    }

                    p.add {
                        margin: 0;
                        margin-left: auto;
                        text-decoration: underline;
                        color: #6268F3;
                        font-size: 1rem;
                        font-weight: 500;

                        @media (max-height:800px) {
                            font-size: 0.9rem;
                        }
                    }
                }

                hr.ratingDivider {
                    margin: 0;
                    color: grey;
                }

                div.ratingContent {
                    padding: 20px 30px;

                    @media (max-height:800px) {
                        padding: 12px 30px;
                    }

                    .bars {
                        width: 100%;
                        margin: 0;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        p.barIndex {
                            margin: 0;
                            margin-right: 8px;
                            margin-bottom: 1px;
                            font-size: 16px;
                            font-weight: 600;
                            color: grey;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                        }

                        .bar {
                            width: 100%;
                            height: 13px;
                            border-radius: 20px;
                            background-color: rgb(245 223 182 / 80%);

                            span {
                                background-color: rgb(248, 193, 90);
                                border-radius: 20px;
                            }
                        }

                    }
                }

            }

            div.dropdownContainer {
                margin-top: 10px;
                padding: 15px 0;
                height: fit-content;

                @media (max-height:800px) {
                    padding: 10px 0;
                }

                .select {
                    min-width: 40px;
                    width: fit-content;
                    padding: 2px 10px;
                    border: none;
                    border-radius: 30px;
                    color: white;
                    background-color: #6268F3;
                    font-size: 0.8rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    div {
                        padding: 10px 30px 10px 15px;

                        @media (max-height:800px) {
                            padding: 7px 25px 7px 12px;
                        }
                    }

                    svg {
                        color: white;
                    }

                }

            }

            div.reviews {
                padding: 0 30px;
                height: -webkit-fill-available;
                overflow: auto;

                &::-webkit-scrollbar {
                    width: 5px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #666;
                }


                .commentDetail {
                    width: 100%;
                    height: fit-content;
                    padding: 10px 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;


                    .userInfo {
                        width: 100%;
                        display: flex;
                        flex-direction: row;

                        .userImageContainer {

                            width: 60px;
                            height: 60px;

                            img.userImage {
                                border-radius: 100%;
                                width: inherit;
                                height: inherit;
                            }
                        }

                        .info {
                            margin-left: 13px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            .namedateContainer {
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                .name {
                                    margin: 0;
                                    font-size: 16px;
                                    font-weight: 600;
                                }

                                .date {
                                    margin: 0;
                                    margin-left: 15px;
                                    font-size: 13px;
                                    color: #707070;
                                    font-weight: 500;
                                }
                            }



                            .star {
                                margin: 0;
                            }
                        }

                    }

                    .userComment {
                        margin-top: 10px;
                        width: 100%;

                        p.commentText {
                            margin: 0;
                            padding-right: 15px;
                            font-size: 14px;
                            letter-spacing: 0px;
                            color: #707070;
                            text-align: left;

                            @media (max-width:1400px) {
                                font-size: 12px;
                            }

                            @media (max-height:700px) {
                                font-size: 12px;
                            }
                        }

                        div.updateButtons {
                            margin: 0;
                            margin-top: 20px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            p.edit,
                            p.delete {
                                margin: 0;
                                padding-right: 15px;
                                font-size: 14px;
                                letter-spacing: 0px;
                                color: #707070;

                                @media (max-width:1400px) {
                                    font-size: 12px;
                                }

                                @media (max-height:700px) {
                                    font-size: 12px;
                                }
                            }

                            p.delete {
                                color: #6268F3;
                            }
                        }

                    }

                }

            }
        }


    }
}