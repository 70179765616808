.modal {
    height: 100%;
    min-height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.5);

    .background {
        position: absolute;
        background-color: rgb(0, 0, 0, 0.5);
        width: 100%;
        min-height: 100vh;
        z-index: 1;

    }



}