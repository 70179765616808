.protectedLayout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    height: 100vh;
    background-color: lightgrey;



    .Navbar {
        width: 100%;
        padding: 10px 50px;
        display: flex;
        align-items: center;
        background-color: white;

        .brand {
            width: 40px;
            height: 40px;

            .image {
                width: 100%;
                height: 100%;
            }
        }


        .search {
            margin-left: auto;
            display: flex;
            flex-direction: row;
            width: 25rem;
            height: auto;
            min-height: 30px;
            padding: 0px 20px;
            border-radius: 40px;
            justify-content: center;
            align-items: center;
            border: 1px solid lightgray;

            input {
                width: 100%;
                height: 100%;
                background-color: transparent;
                padding: 0 10px;
                color: grey;
                font-size: 16px;
                border: none;
                outline: none;
            }

            .searchButton {
                width: 40px;
                height: 35px;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: grey;
                cursor: pointer;
                font-size: 1.5rem;
            }


        }

        .icons {
            height: inherit;
            display: flex;
            justify-content: center;
            align-items: center;

            div {
                height: 100%;
                border-radius: 100%;
                background-color: lightgrey;
                margin: 0 20px;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                svg {
                    font-size: 1.2rem;
                    color: rgb(130, 129, 129);
                }


            }

            img {
                max-width: 40px;
                max-height: 40px;
                min-width: 40px;
                min-height: 40px;
                margin-left: 20px;
                border-radius: 100%;
                border: 0.5px solid lightgrey;
            }
        }


    }


    .layout {
        background-color: #F7F7FA;
        height: calc(100vh - 60px);

        .sider {
            height: calc(100% - 10px);
            width: 100%;
            position: relative;
            background-color: white;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            margin: 10px 0;
            padding: 10px 0;


            .children {
                width: 100%;
                height: inherit;
                display: flex;
                flex-direction: column;
                overflow: overlay;

            }

            .logo {
                margin: 50px 0;
                text-align: center;
                cursor: pointer;
            }

            .menu {
                display: flex;
                flex-direction: column;
                overflow: overlay;

                &::-webkit-scrollbar {
                    display: none;
                }

                .item {
                    margin: 5px 0;
                    padding: 12px 35px;
                    text-decoration: none;
                    display: flex;
                    flex-direction: row;
                    justify-content: left;
                    align-items: center;
                    color: grey;
                    font-weight: 600;
                    font-size: 0.88rem;

                    &.active {
                        color: #6268F3;
                    }

                    @media(max-width:1640px) and (min-width:1530px) {
                        padding: 18px 20px;
                    }

                    @media(max-width:1400px) and (min-width:1300px) {
                        padding: 18px 20px;
                    }


                    @media(max-width:1300px) {
                        padding: 18px 20px;
                    }

                    p {
                        margin: 0 20px;
                    }
                }
            }
        }


        .routeContent {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            height: inherit;
            overflow: hidden;


            .content {
                width: 100%;
                display: flex;
                flex-direction: column;
                height: inherit;
                overflow-y: auto;
                overflow-x: hidden;

            }


        }
    }




}