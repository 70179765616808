.postfeedManagement {
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .banner {
        width: 100%;
        height: 17%;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .content {
        width: 100%;
        height: -webkit-fill-available;
        padding: 20px 40px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .title {
            width: 100%;
            padding: 20px 0;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid lightgrey;

            h1.header {
                font-size: 1.6rem;
                margin: 0;
                margin-right: auto;
            }

            button.create {
                margin-left: auto;
                padding: 8px 20px;
                border: none;
                border-radius: 20px;
                color: white;
                background-color: #6268F3;
                font-size: 0.8rem;
                display: flex;
                justify-content: center;
                align-items: center;

            }
        }

        .loader,
        .noPost {
            height: inherit !important;
            display: flex;
            align-items: center;
            margin: auto;
            color: grey;

            svg {
                font-size: 3rem;

            }

        }

        .cards {
            background-color: transparent;
            height: -webkit-fill-available;
            margin: 0;
            padding-right: 16px;
            padding-bottom: 25px;
            display: flex;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }


            .card {
                position: relative;
                background-color: white;
                border-radius: 15px;
                max-height: fit-content;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                overflow: hidden;

                &:hover {
                    div.updateButtonsContainer {
                        display: block;
                    }
                }

                div.updateButtonsContainer {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                    display: none;
                    cursor: default;

                    .background {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        z-index: 2;
                        background-color: rgba(86, 86, 89, 0.247);
                    }

                    .updateButtons {
                        display: flex;
                        flex-direction: column;
                        margin-left: auto;
                        margin: 10px;
                        position: absolute;
                        z-index: 3;
                        right: 0;

                        button {
                            margin: 0 0 10px auto;
                            background-color: transparent;
                            border: none;

                            svg {
                                width: 30px;
                                height: 30px;
                                color: white;


                            }

                        }
                    }

                }


                img.cardImage {
                    width: 100%;
                    border-radius: 15px;
                    aspect-ratio: 16 / 9;
                }

                .cardInfo {
                    margin: 10px;

                    .tag {
                        margin: 0;
                        font-size: 0.9rem;
                        font-weight: 400;
                        color: #6268F3;
                    }

                    .header {
                        margin: 2px 0;
                        font-size: 1.2rem;
                    }

                    .info {
                        margin: 6px 0;
                        color: grey;
                        font-size: 0.8rem;
                    }

                    .buttons {
                        width: 100%;
                        margin: 20px 0 0;
                        display: flex;
                        flex-direction: row;

                        button {
                            width: 100%;
                            margin: 0 10px;
                            padding: 8px 20px;
                            border: none;
                            border-radius: 20px;
                            color: white;
                            font-size: 0.8rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        button.viewStats {
                            background-color: #6268F3;

                        }

                        button.comments {
                            background-color: white;
                            border: 1px solid grey;
                            color: grey;

                        }

                    }
                }
            }

        }
    }
}