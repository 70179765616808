.menuservicemanagement {
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .content {
        width: 100%;
        height: -webkit-fill-available;
        padding: 20px 40px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .title {
            width: 100%;
            padding: 20px 0;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid lightgrey;

            h1.header {
                font-size: 1.6rem;
                margin: 0;
                margin-right: auto;
            }

            button.create {
                margin-left: auto;
                padding: 8px 20px;
                border: none;
                border-radius: 20px;
                color: white;
                background-color: #6268F3;
                font-size: 0.8rem;
                display: flex;
                justify-content: center;
                align-items: center;

            }
        }

        .loader,
        .noPost {
            height: inherit !important;
            display: flex;
            align-items: center;
            margin: auto;
            color: grey;

            svg {
                font-size: 3rem;

            }

        }

        .cards {
            background-color: transparent;
            height: -webkit-fill-available;
            margin: 0;
            padding-right: 16px;
            padding-bottom: 25px;
            display: flex;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }


            .card {
                position: relative;
                background-color: white;
                border-radius: 15px;
                max-height: fit-content;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                overflow: hidden;

                &:hover {
                    div.updateButtonsContainer {
                        display: block;
                    }
                }

                div.updateButtonsContainer {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                    background-color: rgba(86, 86, 89, 0.247);
                    display: none;
                    cursor: default;

                    .updateButtons {
                        display: flex;
                        flex-direction: column;
                        margin-left: auto;
                        margin: 10px;

                        button {
                            margin: 0 0 10px auto;
                            background-color: transparent;
                            border: none;

                            svg {
                                width: 30px;
                                height: 30px;
                                color: white;


                            }
                        }
                    }

                }


                img.cardImage {
                    width: 100%;
                    aspect-ratio: 7 /6;
                }

                .cardInfo {
                    padding: 7px 20px;

                    .header {
                        margin: 2px 0;
                        font-size: 1.2rem;
                        font-weight: 600;
                        color: #2B2E41;
                        letter-spacing: 0px;
                    }

                    .info {
                        margin: 4px 0;
                        font-size: 0.8rem;
                        letter-spacing: -0.13px;
                        color: #2B2E41
                    }

                    div.prices {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin: 8px 0;

                        p {
                            margin: 0;
                        }

                        p.newPrice {
                            font-size: 18px;
                            font-weight: 600;
                            color: #6268F3;
                            margin-right: 6px;

                        }

                        p.oldPrice {
                            font-size: 15px;
                            font-weight: 400;
                            color: #707070;
                            text-decoration: line-through;
                        }
                    }

                    @media(max-width : 1350px)and (min-width: 991px){
                        .header {
                            font-size: 1rem;
                        }
                        .info {
                            font-size: 0.75rem;
                        }
    
                        div.prices {
                            margin: 5px 0;
                            p.newPrice {
                                font-size: 15px;
                            }
                            p.oldPrice {
                                font-size: 13px;

                            }
                        }
                    }
                }
            }

        }
    }
}