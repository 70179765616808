.brands {
    width: 100%;
    padding: 7rem 12vw;
    background-image: url("../images/brand-background.png");
    background-position: fixed;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    @media (max-width:1300px) {
        padding: 7rem 10vw;

    }


    .imageContainer {
        display: flex;
        align-items: center;
    }

    .contentImage {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        .image {
            max-height: 250px;
            max-width: 250px;


            @media (max-width:1300px) {
                max-height: 200px;
                max-width: 200px;
            }

            &.even {
                margin-top: 70px;
                margin-right: 20px;

                @media (max-width:1300px) {
                    margin-top: 40px;
                    margin-right: 20px;
                }

            }
        }

    }



    .content {
        padding: 2rem 8rem 2rem 0;

        @media (max-width:1500px) {
            padding: 2rem 6vw 2rem 0;

        }


        h1 {
            font-size: 3rem;
            font-weight: 900;
            color: white;


            @media (max-width:1200px) {
                font-size: 2.5rem;

            }
        }

        p {
            margin: 50px 0;
            font-size: 17px;
            letter-spacing: 1.2px;
            line-height: 1.7;
            color: #FFFFFF;


            @media (max-width:1200px) {
                font-size: 15px;

            }
        }

        .button {
            margin-top: 10px;
            background-color: white;
            border-radius: 50px;
            padding: 17px 60px;
            font-size: 17px;
            text-align: center;
            border: none;

        }
    }


}