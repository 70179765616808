.offerAndPromotion {
    width: 100%;
    height: inherit;
    padding: 2rem 3rem;
    overflow: hidden;

    .backButtonContainer {
        width: fit-content;
        height: 5%;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;


        @media (max-height:800px) {
            margin-bottom: 10px;
        }

        .icon {
            font-size: 2rem;
            margin-top: 4px;
        }

        h5.back {
            margin: 0 5px;
            font-size: 1.5rem;

            @media (max-height:800px) {
                font-size: 1.2rem;
            }
        }
    }

    .container {
        width: 100%;
        height: 95%;
        padding: 2rem;
        background-color: white;
        border-radius: 20px;
        overflow: hidden auto;

        &::-webkit-scrollbar {
            display: none;
        }


        @media (max-width:1400px) {
            padding: 2rem 2rem;
        }


        h1.header {
            font-size: 1.7rem;
            color: #2B2E41;
            font-weight: 700;
        }

        h3.subHeader {
            font-size: 1.1rem;
            color: #2B2E41;
            font-weight: 700;
        }

        p.subTitle {
            font-size: 14px;
            color: grey;
            font-weight: 500;

        }

        hr {
            border: 1px 0 0 0 solid grey;
            margin: 20px 0;

        }

        .form {
            padding: 60px 0px;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            @media (min-width:1200px)and (max-width: 1535px) {
                width: 60%;

            }

            .labelContainer {
                display: flex;

                .label {
                    margin: 0;
                    margin-top: 0.3rem;

                    @media (min-width:1500px)and (max-width: 1635px),
                    (min-width:991px)and (max-width: 1350px) {
                        font-size: 14px;

                    }
                }


            }

            .submit {
                width: 30%;
                background-color: #6268F3;
                color: white;
                border-radius: 50px;
                padding: 12px 0;
                font-size: 18px;
                font-weight: 700;
                border: none;
                margin: 80px 0 20px 20px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                @media (min-width:1500px)and (max-width: 1535px) {
                    width: 55%;

                }

                @media (max-width: 1535px) {
                    width: 70%;

                }

                &.disabled {
                    background-color: #b6b8f4;
                    cursor: not-allowed;
                }

                &.submitted {
                    background-color: #a3a5e4;
                    cursor: not-allowed;
                    position: relative;
                }

                span {
                    color: white;
                    margin: 0 20px;
                    position: absolute;
                    right: 16%;
                }

            }
        }
    }
}