.membership {
    width: 100%;
    padding: 7rem 15vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../images/membership_bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (max-width:1300px) {
        padding: 7rem 10vw;

    }

    .title {
        font-size: 3rem;
        font-weight: 900;
        color: white;
        width: 60%;
        text-align: center;

        @media (max-width:1400px) {
            width: 70%;

        }


        @media (max-width:1200px) {
            font-size: 2.5rem;
            width: 80%;

        }
    }

    .subtitle {
        margin: 30px 0;
        font-size: 16px;
        letter-spacing: 0.91px;
        color: #FFFFFF;
        font-weight: 600;
        text-align: center;
        opacity: 0.8;


        @media (max-width:1200px) {
            font-size: 15px;
            letter-spacing: 0.8px;

        }
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;

        .tile {
            width: 100%;
            padding: 2.5rem 5%;
            position: relative;
            display: flex;
            flex-direction: column;
            text-align: center;
            background-color: white;
            border-radius: 20px;

            @media (max-width:1500px) {
                padding: 2.5rem 4%;

            }

            @media (max-width:1200px) {
                padding: 2.5rem 5.5%;

            }

            &.even {
                padding: 3rem 4.2rem;
                position: relative;
                background-color: #8591F1;

                @media (max-width:1500px) {
                    padding: 2.5rem 6%;

                }

                @media (max-width:1400px) {
                    padding: 2.5rem 5%;

                }

                @media (max-width:1200px) {
                    padding: 2.5rem 5.5%;

                }

                .tag {
                    left: 50%;
                    transform: translateX(-50%);
                    position: absolute;
                    top: 0;
                    background-color: white;

                    p {
                        margin: 3px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #2B2E41;
                    }

                    .triangle {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        border-left: 50px solid transparent;
                        border-right: 50px solid transparent;

                        border-bottom: 8px solid #8591F1;
                    }

                }

                .header {
                    color: white;
                    font-size: 1.8rem;
                }

                .pricing {
                    display: flex;
                    flex-direction: row;
                    margin: 10px auto;

                    .symbol {
                        font-size: 28px;
                        margin-top: 40%;
                        color: white;
                    }

                    h1.amount {
                        font-size: 5rem;
                        font-weight: 900;
                        margin: 0 5px;
                        color: white;
                    }

                    .time {
                        margin-top: 30%;
                        font-size: 26px;
                        color: white;
                        font-weight: 500;
                    }

                }

                .points {
                    width: 100%;

                    .info {
                        font-size: 14px;
                        letter-spacing: 0.91px;
                        color: white;
                        font-weight: 400;
                        margin: 5px 0;
                        line-height: 1.7;

                        @media (max-width:1500px) {
                            font-size: 14px;

                        }

                        img{
                            color: white;
                            margin-right: 10px;
                        }
                    }
                }

                .button {
                    margin: 40px auto 0;
                    background-color: white;
                    color: #2B2E41;
                    padding: 10px 35px;

                }
            }

            .header {
                color: #2B2E41;
                font-size: 1.4rem;
                font-weight: 700;
            }

            .pricing {
                margin: 5px auto;
                display: flex;
                flex-direction: row;

                .symbol {
                    color: black;
                    font-size: 20px;
                    margin-top: 40%;
                    margin-right: -3px;
                    font-weight: 700;
                }

                h1.amount {
                    height: fit-content;
                    font-size: 3.6rem;
                    color: #6268F3;
                    font-weight: 800;
                    margin: 0 5px;
                }

                .time {
                    font-size: 20px;
                    margin-top: 30%;
                    margin-left: -3px;
                    letter-spacing: 0.91px;
                    color: #a8a7a7;
                    font-weight: 700;

                }
            }

            .points {
                width: 100%;

                .info {
                    font-size: 13px;
                    letter-spacing: 0.6px;
                    color: #707070;
                    font-weight: 400;
                    margin: 3px 0;
                    text-align: left;


                    @media (max-width:1500px) {
                        font-size: 12px;

                    }

                    img{
                        color: black;
                        margin-right: 5px;
                    }
                }

            }



            .button {
                display: inline;
                margin: 30px auto 0;
                background-color: #6268F3;
                color: white;
                border-radius: 50px;
                padding: 8px 30px;
                font-size: 12px;
                text-align: center;
                border: none;
                width: fit-content;
            }
        }
    }



}