.mainDiv {
    width: 50%;
    height: 75vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;


    @media(max-width:1250px) {
        width: 80%;
    }

    @media(max-height:900px) {
        height: 75vh;
    }

    .createMenuModal {
        height: 100%;
        width: 80%;
        padding: 40px;
        background-color: white;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .title {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 0;

            h1.header {
                font-size: 1.3rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            button.close {
                height: fit-content;
                margin-left: auto;
                padding: 6px 12px;
                border: none;
                border-radius: 100%;
                font-size: 0.8rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: lightgrey;
            }
        }

        hr {
            margin-bottom: 5px;
        }

        .content {
            width: 100%;
            height: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }


            .form {
                width: 50%;
                height: inherit;
                margin: 20px 0;

                @media(max-width:1600px) {
                    width: 70%;
                }
            }
        }

        h3.uploadHeader {
            margin: 20px 0 14px;
            font-size: 1rem;
            color: grey;
        }

        .uploadContainer {
            margin: 30px 0;


            p {
                font-size: 1rem;
                font-weight: 600;
                color: grey;
            }

            .imageContainer {
                width: 100px;
                height: 100px;
                position: relative;
                overflow: hidden;
                cursor: pointer;

                &:hover {
                    .cancel {
                        z-index: 1;
                    }
                }

                .cancel {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: rgb(0, 0, 0, 0.5);
                    border-radius: 10px;


                    button {
                        position: absolute;
                        background-color: transparent;
                        font-size: 1rem;
                        color: white;
                        right: 5px;
                        top: 5px;
                        border: none;

                    }
                }

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border: 1px solid grey;
                    border-radius: 10px;
                }
            }

            .uploadButton {
                background-color: #7A849C1A;
                padding: 30px;
                color: grey;
                font-size: 2rem;
            }

        }

        button.submit {
            width: 100%;
            margin: 30px 0 10px;
            padding: 12px 0;
            background-color: #6268F3;
            color: white;
            border-radius: 50px;
            font-size: 18px;
            font-weight: 700;
            border: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            &.submitted {
                background-color: #a3a5e4;
                cursor: not-allowed;
                position: relative;
            }

            span {
                color: white;
                margin: 0 20px;
                position: absolute;
                right: 13%;
            }

        }
    }
}