.title {
    width: 100%;
    min-height: 100vh;
    height: fit-content;
    background-image: url("../images/background.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 7rem 13vw;
    color: white;

    .imageTile {
        position: relative;

        .mobileImage {
            position: absolute;
            max-height: 750px;
            width: 400px;
            top: -20%;
            left: 10vw;





            @media(max-width : 1200px) {
                left: 5vw;

            }

            @media(max-height:900px) {
                max-height: 650px;
                top: -7%;

            }


            @media(max-height:650px) {
                max-height: 500px;
                top: -4%;

            }

            @media(min-width : 1650px) and (max-height : 750px) {
                max-height: 580px;
                max-width: 500px;
            }

            @media(max-width : 1649px) and (max-height : 750px) {
                max-height: 500px;
                top: -2%;
            }

        }
    }




    h1 {
        margin: 0;
        font-size: 3.3rem;
        font-weight: 900;

        @media(max-width : 1300px) {
            font-size: 3rem;
        }

        @media(max-width : 1200px) {
            font-size: 2.5rem;
        }

    }

    p {
        margin: 40px 0;
        font-size: 17px;
        line-height: 3.3ch;

        @media(max-width : 1300px) {
            margin: 20px 0;
            font-size: 14px;
        }
    }

    .search {
        width: 100%;
        padding: 5px 5px 5px 40px;
        margin: 60px 0;
        background-color: white;
        border-radius: 50px;
        min-height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        @media(max-width : 1200px) {
            height: 55px;
            min-height: 55px;
        }


        input {
            background-color: transparent;
            border: none;
            width: 100%;
            height: 40px;
            padding: 10px 0;

            &:focus {
                outline: none;
            }
        }

        .button {
            background-color: #6268F3;
            border-radius: 50px;
            padding: 20px 30px;
            font-size: 14px;
            margin: 0 0 0 6px;
            width: 200px;
            text-align: center;

            @media(max-width : 1200px) {
                padding: 10px 20px;
            }


            a {
                text-decoration: none;
                color: white;
                font-weight: 500;
                font-size: 1rem;
                text-align: center;
            }
        }
    }

    .video {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;


        .button {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            background-color: #6268F3;
            padding: 20px;
            box-shadow: white 0px 0px 8px 0.5px;
            margin-right: 40px;
            font-size: 2rem;

            @media(max-width : 1200px) {
                padding: 15px;
            }


        }

        h5 {
            font-size: 1.2rem;
        }
    }

}