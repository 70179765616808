.work {
    width: 100%;
    padding: 7rem 15vw;
    text-align: center;
    justify-content: center;

    @media (max-width:1300px) {
        padding: 7rem 10vw;

    }


    h1.title {
        color: #474F61;
        font-size: 3rem;
        font-weight: 900;
        margin-bottom: 6rem;
        
        @media (max-width:1200px) {
            font-size: 2.5rem;
            margin-bottom: 4rem;

        }
    }

    .numberContainer {
        display: inline-block;
        padding: 30px 40px;
        border-radius: 100%;

        @media (max-width:1200px) {
            padding: 18px 30px;

        }

        &.odd {
            background-color: #6268F3;

        }

        &.even {
            background-color: #373E4F;

        }

        h1.number {
            font-size: 2rem;
            color: white;
            display: inline-block;
            min-width: 20px;
            width: 20px;
            max-width: 20px;
            margin: 0;

        }


    }

    p {
        margin: 25px 0;
        font-size: 14px;
        letter-spacing: 0.91px;
        color: #707070;
        font-weight: 600;

        
        @media (max-width:1200px) {
            font-size: 12px;
            letter-spacing: 0.8px;

        }
    }

    .imageContainer {
        position: relative;

        .image {
            position: absolute;
            top: 8%;

            img {
                width: 100%;
                height: 100%;
            }
        }


    }

}