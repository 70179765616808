.manageProfile {
    width: 100%;
    height: inherit;
    padding: 2rem 3rem;
    overflow: hidden;

    .container {
        width: 100%;
        height: 100%;
        padding: 2rem;
        background-color: white;
        border-radius: 20px;
        overflow: hidden auto;

        &::-webkit-scrollbar {
            display: none;
        }


        @media (max-width:1400px) {
            padding: 2rem 2rem;
        }


        h1 {
            font-size: 1.7rem;
            color: #2B2E41;
            font-weight: 700;
        }

        hr {
            border: 1px 0 0 0 solid grey;
        }

        .form {
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 70%;

            @media (max-width:1400px) {
                width: 100%;
            }

            .uploadContainer {
                margin: 30px 0;


                p {
                    font-size: 1rem;
                    font-weight: 600;
                    color: grey;
                }

                .imageContainer {
                    width: 100px;
                    height: 100px;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;

                    &:hover {
                        .cancel {
                            z-index: 1;
                        }
                    }

                    .cancel {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: rgb(0, 0, 0, 0.5);
                        border-radius: 10px;


                        button {
                            position: absolute;
                            background-color: transparent;
                            font-size: 1rem;
                            color: white;
                            right: 5px;
                            top: 5px;
                            border: none;

                        }
                    }

                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        border: 1px solid grey;
                        border-radius: 10px;
                    }
                }

                .uploadButton {
                    background-color: #7A849C1A;
                    padding: 30px;
                    color: grey;
                    font-size: 2rem;
                }

            }



            .submit {
                width: 50%;
                background-color: #6268F3;
                color: white;
                border-radius: 50px;
                padding: 12px 0;
                font-size: 18px;
                font-weight: 700;
                border: none;
                margin: 20px 0 20px 20px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                &.disabled {
                    background-color: #b6b8f4;
                    cursor: not-allowed;
                }

                &.submitted {
                    background-color: #a3a5e4;
                    cursor: not-allowed;
                    position: relative;

                }

                span {
                    color: white;
                    margin: 0 20px;
                    position: absolute;
                    right: 27%;
                }

            }


        }
    }
}