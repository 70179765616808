.footer {
    display: flex;
    flex-direction: column;

    .service {
        padding: 7rem 13vw;
        background-image: url("../images/service_bg.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @media (max-width:1300px) {
            padding: 7rem 10vw;

        }

        .subtitle {
            font-size: 15px;
            letter-spacing: 0.91px;
            color: #707070;
            margin: 15px 0;

            @media (max-width:1200px) {
                font-size: 13px;

            }
        }

        .socialContainer {
            width: 100%;
            display: flex;
            flex-direction: row;

            .social {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 11px;
                background-color: #6268F3;
                border-radius: 50%;
                border: none;
                margin-right: 15px;

                svg {
                    color: white;
                }
            }
        }

        h3.title {
            font-size: 18px;
            font-weight: 700;
            color: #2B2E41;

        }

        .container {
            margin: 15px 0;

            .buttonContainer {
                margin: 15px 0;
                display: flex;
                flex-direction: column;

                img {
                    margin-bottom: 20px;
                }
            }

            p.links {
                font-size: 13px;
                letter-spacing: 0.91px;
                color: #707070;
                margin: 12px 0;
                font-weight: 600;

                @media (max-width:1200px) {
                    font-size: 11px;

                }
            }
        }



    }

    .copyright {
        padding: 15px 0;
        text-align: center;
        background-color: #6268F3;

        p {
            text-align: center;
            margin: 0;
            color: white;
            letter-spacing: 0.91px;
            font-weight: 500;
        }
    }
}