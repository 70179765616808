.rewardManagement {
    width: 100%;
    height: inherit;
    padding: 2rem 3rem;
    overflow: hidden;

    .container {
        width: 100%;
        height: 100%;
        padding: 2rem;
        background-color: white;
        border-radius: 20px;
        overflow: hidden auto;

        &::-webkit-scrollbar {
            display: none;
        }

        h1 {
            font-size: 1.7rem;
            color: #2B2E41;
            font-weight: 700;

        }

        hr {
            border: 1px 0 0 0 solid grey;
        }

        .form {
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 35%;

            @media (max-width:1400px) {
                width: 60%;

            }

            .inputContainer {
                border: none !important;
                margin: 30px 0 0 0 !important;


                label {
                    padding: 0 10px;
                }


            }

            .input,
            .select {
                border-radius: 20px !important;


                span {
                    font-size: 0.6rem !important;
                    width: 20px !important;
                    height: 20px !important;
                    top: inherit !important;
                    right: 10px !important;
                }


                &:focus-visible {
                    outline: none !important;


                }

                fieldset {
                    border-color: lightgrey !important;
                    top: -8px !important;

                }

                &:hover,
                &:focus-within {
                    fieldset {
                        border-color: lightgrey !important;
                    }
                }

            }


            .submit {
                width: 100%;
                background-color: #6268F3;
                color: white;
                border-radius: 50px;
                padding: 12px 0;
                font-size: 18px;
                font-weight: 700;
                border: none;
                margin: 5rem auto 0;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;



                &.submitted {
                    background-color: #a3a5e4;
                    cursor: not-allowed;
                    position: relative;
                }

                span {
                    color: white;
                    margin: 0 20px;
                    position: absolute;
                    right: 18%;
                }

            }
        }
    }

    .colored {
        background-color: white !important;
        color: #6268F3 !important;




    }


}