.about {
    width: 100%;
    padding: 9rem 15vw;

    @media (max-width:1300px) {
        padding: 9rem 10vw;

    }

    .contentImage {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: baseline;

        .image {
            width: 100%;
            height: 100%;
            max-height: 700px;

            @media (max-width:1500px) {
                max-height: 600px;
        
            }

            @media (max-width:1100px) {
                max-height: 500px;
        
            }
        }

    }



    .content {
        padding: 0 8rem;

        @media (max-width:1500px) {
            padding: 0 6vw;

        }


        h1 {
            color: #474F61;
            font-size: 3rem;
            font-weight: 900;

            @media (max-width:1200px) {
                font-size: 2.5rem;

            }
        }

        p {
            margin: 40px 0;
            font-size: 18px;
            letter-spacing: 0.91px;
            color: #707070;

            @media (max-width:1200px) {
                font-size: 15px;

            }
        }

        .button {
            margin-top: 10px;
            background-color: #6268F3;
            color: white;
            border-radius: 50px;
            padding: 17px 60px;
            font-size: 17px;
            text-align: center;
            border: none;

        }
    }


}