.offerAndPromotion {
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .banner {
        width: 100%;
        height: 17%;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .content {
        width: 100%;
        height: -webkit-fill-available;
        padding: 20px 40px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .title {
            width: 100%;
            padding: 20px 0;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid lightgrey;

            h1.header {
                font-size: 1.6rem;
                margin: 0;
                margin-right: auto;
            }

            button.create {
                margin-left: auto;
                padding: 8px 20px;
                border: none;
                border-radius: 20px;
                color: white;
                background-color: #6268F3;
                font-size: 0.8rem;
                display: flex;
                justify-content: center;
                align-items: center;

            }
        }

        .loader,
        .noPost {
            height: inherit !important;
            display: flex;
            align-items: center;
            margin: auto;
            color: grey;

            svg {
                font-size: 3rem;

            }

        }

        .cards {
            background-color: transparent;
            height: -webkit-fill-available;
            margin: 0;
            padding-right: 16px;
            padding-bottom: 25px;
            display: flex;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }


            .card {
                position: relative;
                background-color: white;
                border-radius: 15px;
                max-height: fit-content;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                overflow: hidden;

                .cardInfo {

                    .top {
                        padding: 20px;

                        .tag {
                            margin: 0;
                            font-size: 0.9rem;
                            font-weight: 400;
                            color: #6268F3;
                            text-align: center;
                        }


                        .info {
                            margin: 6px 0;
                            color: black;
                            font-size: 0.8rem;

                            span {
                                color: grey;
                                margin-left: 4px;
                            }
                        }
                    }

                    .bottom {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        padding: 10px 20px;
                        background-color: #8591F1;

                        .titleAndEditContainer {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 5px;

                            p.header {
                                color: #373E4F;
                                font-size: 15px;
                                margin: 0;
                                color: white;
                            }

                            .editContainer {
                                margin-left: auto;

                                button {
                                    margin: 0 2px 5px auto;
                                    background-color: transparent;
                                    border: none;

                                    svg {
                                        width: 18px;
                                        height: 18px;
                                        color: white;


                                    }
                                }
                            }
                        }



                        p.date {
                            color: white;
                            font-size: 14px;
                            margin: 2px 0;

                        }
                    }
                }
            }

        }
    }
}