@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible&display=swap');

.signIn {
    width: 100%;
    min-height: 100vh;


    .title {
        min-height: 100vh;
        background-image: url("./images/background.png");
        background-size: 100% 100%;
        position: relative;
        justify-content: center;

        .content {
            width: 100%;
            position: absolute;
            bottom: 4rem;
            z-index: 1;
            color: white;
            text-align: center;

        }
    }

    .form {
        padding: 2rem 10rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width : 1500px) {
            padding: 3rem 8rem;

        }


        @media (max-width : 1400px) {
            padding: 3rem 6rem;

        }

        @media (max-width : 1200px) {
            padding: 3rem 3rem;

        }

        .header {
            display: flex;
            flex-direction: row;
            text-align: right;
            justify-content: right;
            width: 100%;
            margin-bottom: 25px;

            img {
                margin-right: auto;
                width: 40px;
                height: 40px;
            }

            p {
                margin: 0 10px 0 auto;
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 15px;
                font-family: 'Atkinson Hyperlegible', sans-serif;
            }

            button {
                background-color: #6268F3;
                border-radius: 50px;
                padding: 8px 30px;
                font-size: 14px;
                text-align: center;
                border: none;
                margin-left: 20px;
                margin: 0 6px;
                color: white;
            }


        }

        h1 {
            width: 100%;
            margin: 10px 0;
            font-weight: 800;
            font-size: 2rem;
        }

        p {
            margin: 10px 0;
            color: grey;
            font-size: 15px;
        }

        hr {
            margin: 12px 0;
            border: 0.6px 0 0 0 solid grey;
            width: 100%;
        }

        .fields {
            width: 80%;
            margin: 0px auto 50px 0;



            .description {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0 15px;
                margin-top: 10px;


                .reset {
                    margin: auto;
                    color: #6f81a5;
                    font-size: 0.8rem;
                    text-decoration: none;
                    font-weight: 500;
                    cursor: pointer;
                }
            }


        }

        .submit {
            width: 100%;
            margin-top: 60px;
            padding: 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 50px;
            font-size: 18px;
            font-weight: 700;
            color: white;
            background-color: #6268F3;


            &.submitted {
                background-color: #a3a5e4;
                cursor: not-allowed;
                position: relative;
            }

            span {
                color: white;
                margin: 0 20px;
                position: absolute;
                right: 27%;
            }
        }
    }



}