.postDetails {
    width: 100%;
    height: inherit;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;

    @media (max-height:800px) {
        padding: 15px 40px;
    }

    .backButtonContainer {
        width: fit-content;
        display: flex;
        margin-bottom: 20px;
        flex-direction: row;
        align-items: center;
        cursor: pointer;


        @media (max-height:800px) {
            margin-bottom: 10px;
        }

        .icon {
            font-size: 2rem;
            margin-top: 4px;
        }

        h5.back {
            margin: 0 5px;
            font-size: 1.5rem;

            @media (max-height:800px) {
                font-size: 1.2rem;
            }
        }
    }

    .loader {
        width: 100%;
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        color: grey;
    }

    .content {
        width: 100%;
        height: inherit;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
        overflow: hidden;
        background-color: white;


        div.product,
        div.comments {
            width: 100%;
            height: 50%;
            padding: 20px;

        }

        hr {
            border: 0.1px solid lightgrey;
            width: 95%;
            opacity: 0.8;
            margin: 10px auto;
        }

        div.product {
            overflow: hidden;

            .detailsContainer {
                width: 100%;
                height: -webkit-fill-available;

                .image_stats_container {
                    width: 100%;
                    height: -webkit-fill-available;

                    .imageContainer {
                        width: 100%;
                        height: 85%;

                        .carousel {
                            width: 100%;
                            height: -webkit-fill-available;
                        }

                        @media (max-width:1535px) {
                            height: 85%;
                        }

                        img.postImage {
                            width: 100%;
                            height: 100%;
                            border-radius: 20px;
                        }
                    }

                    .statsContainer {
                        width: 100%;
                        height: 15%;
                        display: flex;
                        flex-direction: row;

                        // @media (max-width:1535px) {
                        //     height: 15%;
                        //     flex-direction: column;
                        // }

                        .counts {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            text-align: center;

                            // @media (max-width:1535px) {
                            //     min-width: -webkit-fill-available;
                            //     flex-direction: column;
                            // }

                            @media (max-height:800px) {
                                font-size: 0.8rem;
                            }

                            svg {
                                color: #2B2E41;

                                &.red {
                                    color: red;
                                }
                            }

                            p.label {
                                margin: 0;
                                margin-left: 6px;
                                color: #2B2E41;
                                letter-spacing: 0.36px;
                                font-weight: 400;

                                // @media (max-width:1535px) {
                                //     margin-left: 0;
                                // }


                            }


                        }
                    }
                }

                .details {
                    width: 100%;
                    height: 95%;
                    padding: 15px 30px;
                    overflow: auto;


                    &::-webkit-scrollbar {
                        display: none;
                    }

                    @media (max-width:1200px) {

                        padding: 12px 20px;
                    }



                    p.tag {
                        margin: 0;
                        color: #6268F3;
                        font-size: 16px;
                        font-weight: 600;

                        @media (max-width:1200px) {
                            font-size: 14px;
                        }
                    }

                    h5.header {
                        font-size: 1.3rem;
                        font-weight: 700;
                        margin: 7px 0 10px;

                        @media (max-width:1200px) {
                            font-size: 1.1rem;
                        }
                    }

                    p.info {
                        margin: 0;
                        letter-spacing: 0.3px;
                        color: #707070;
                        font-weight: 450;
                        font-size: 14px;

                        @media (max-width:1200px) {
                            font-weight: 400;
                            font-size: 13px;
                        }

                        @media (max-height:700px) {
                            font-weight: 400;
                            font-size: 13px;
                        }

                    }
                }
            }
        }

        div.comments {
            padding: 10px 40px 20px;



            .commentsContainer {
                width: 100%;
                height: 100%;

                h5.header {
                    height: 15%;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    font-size: 1.3rem;
                    font-weight: 700;

                    @media (max-height:800px) {
                        height: 20%;
                    }

                    @media (max-height:800px) {
                        font-size: 1.1rem;
                    }
                }

                .userComments {
                    height: 85%;
                    width: 100%;
                    overflow: auto;



                    @media (max-height:700px) {
                        height: 80%;
                    }

                    &::-webkit-scrollbar {
                        width: 5px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #666;
                    }


                    .emptyCommentContainer {
                        display: flex;
                        justify-content: center;
                        margin: auto;


                        h3.emptyHeader {
                            height: inherit !important;
                            display: flex;
                            align-items: center;
                            margin: auto;
                            color: grey;
                            text-align: center;

                        }
                    }

                    .commentDetail {
                        width: 100%;
                        height: fit-content;
                        padding: 10px 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;


                        .userInfo {
                            width: 100%;
                            display: flex;
                            flex-direction: row;

                            .userImageContainer {

                                width: 60px;
                                height: 60px;

                                img.userImage {
                                    border-radius: 100%;
                                    width: inherit;
                                    height: inherit;
                                }
                            }

                            .info {
                                margin-left: 13px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;

                                .name {
                                    margin: 0;
                                    font-size: 16px;
                                    font-weight: 600;
                                }

                                .date {
                                    margin: 0;
                                    font-size: 13px;
                                    color: #707070;
                                    font-weight: 400;
                                }
                            }

                        }

                        .userComment {
                            margin-top: 10px;
                            margin-right: auto;



                            p.commentText {
                                font-size: 14px;
                                letter-spacing: 0px;
                                color: #707070;
                                padding: 0 10px 0 0;

                                @media (max-width:1400px) {
                                    font-size: 12px;
                                }

                                @media (max-height:700px) {
                                    font-size: 12px;
                                }
                            }

                        }

                    }

                }

            }

        }
    }
}