.mainDiv {
    width: 60%;
    height: 65vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;


    @media(max-width:1250px) {
        width: 80%;
    }

    @media(max-height:900px) {
        height: 75vh;
    }

    .createPostModal {
        height: 100%;
        width: 80%;
        padding: 40px;
        background-color: white;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        overflow: auto;



        &::-webkit-scrollbar {
            display: none;
        }

        .title {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 0;

            h1.header {
                font-size: 1.3rem;
                display: flex;
                justify-content: center;
                align-items: center;

            }

            button.close {
                height: fit-content;
                margin-left: auto;
                padding: 6px 12px;
                border: none;
                border-radius: 100%;
                font-size: 0.8rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: lightgrey;
            }
        }

        hr {
            margin-bottom: 5px;
        }

        .content {
            width: 100%;
            height: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }


            .form {
                width: 50%;
                height: inherit;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                @media(max-width:1600px) {
                    width: 70%;
                }
            }
        }

       

        button.submit {
            width: 100%;
            margin: 80px 0 10px;
            padding: 12px 0;
            background-color: #6268F3;
            color: white;
            border-radius: 50px;
            font-size: 18px;
            font-weight: 700;
            border: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            &.submitted {
                background-color: #a3a5e4;
                cursor: not-allowed;
                position: relative;
            }

            span {
                color: white;
                margin: 0 20px;
                position: absolute;
                right: 21%;
            }

        }


    }

}