.navbar {
    width: 100%;
    padding: 30px 15vw;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    position: absolute;

    @media(max-width : 1400px) {
        padding: 30px 7vw;
    }

    @media(max-width : 1100px) {
        padding: 30px 3vw;
    }

    @media(max-height : 800px) {
        padding: 15px 3vw;
    }

    .logo {
        margin-right: auto;

        img {
            width: 50px;
            height: 50px;
        }
    }

    .links {
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .link {
            margin: 0 20px;

            a {
                text-decoration: none;
                color: white;
                font-size: 16px;
                font-weight: 400;

                @media(max-width : 1200px) {
                    font-size: 14px;
                }
            }

        }
    }

    .login {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .button {
            background-color: white;
            border-radius: 20px;
            padding: 10px 40px;
            font-size: 14px;
            margin: 0 6px;
            cursor: pointer;
            text-decoration: none;
            color: #474f61;
            font-weight: 500;


            &.signUpButton {
                background-color: transparent;
                border: 1px solid white;
                color: white;

            }


        }
    }





}